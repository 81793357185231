/* Change by a global css in theme repo */

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('/src/fonts/IBMPlexSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans Light';
  src: url('/src/fonts/IBMPlexSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans Bold';
  src: url('/src/fonts/IBMPlexSans-Bold.ttf') format('truetype');
}

#CybotCookiebotDialogHeader {
  display: none !important;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('/src/fonts/IBMPlexSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans Light';
  src: url('/src/fonts/IBMPlexSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'IBM Plex Sans Bold';
  src: url('/src/fonts/IBMPlexSans-Bold.ttf') format('truetype');
}

html {
  font-size: 16px;
  box-sizing: border-box;
}

body {
  overflow-y: overlay;
  font-family: sans-serif;
}

body.wizzard-open {
  overflow-y: hidden;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  font-family: 'IBM Plex Sans';
}

.ant-menu-submenu,
.ant-menu-submenu-popup {
  display: none;
}

.ant-menu-item.ant-menu-item-selected:after {
  left: 0 !important;
  right: initial !important;
  transform: scaleY(1) !important;
  opacity: 1 !important;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
    opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-menu {
  padding-left: 7px;
}

.ant-menu .ant-menu-item {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
  display: flex !important;
  justify-content: center !important;
  padding: initial !important;
}
.ant-popover-inner-content {
  /* padding: 0 10px !important; */
}

.react-datetimerange-picker input[type='number'] {
  appearance: textfield !important;
  -moz-appearance: textfield !important;
}

.ant-checkbox-indeterminate {
  background-color: #b34be9 !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #b34be9;
}

.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #b34be9;
  border-color: #b34be9;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #b34be9 !important;
  border-color: #b34be9 !important;
}
.ant-checkbox-checked::after {
  border: 1px solid #b34be9 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #b34be9 !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: white !important;
  width: 10px !important;
  height: 3px !important;
}

.react-flow__edge-path {
  stroke: #d688ff !important;
  stroke-width: 2px !important;
}
.react-flow__edge-path-white {
  stroke: #ffffff !important;
  stroke-width: 1px !important;
}
.react-flow__edge-path {
  stroke-width: 1px !important;
}

.react-flow__edge-stroke {
  stroke: #d688ff !important;
  stroke-width: 2px !important;
  stroke-dasharray: 10;
}
.react-flow__edge-stroke-white {
  stroke: #ffffff !important;
  stroke-width: 2px !important;
  stroke-dasharray: 10;
}

.react-flow__edge-dots {
  stroke: #d688ff !important;
  stroke-width: 2px !important;
  stroke-dasharray: 4;
}

.react-flow__edge-path-stroke {
  stroke: transparent !important;
  stroke-width: 6px !important;
  fill: none;
  cursor: pointer;
}

.react-flow-dataflows-container .react-flow__edge-path {
  stroke: #ffffff !important;
  stroke-width: 2px !important;
}
.react-flow-dataflows-container .react-flow__edge-path:hover {
  /*hover color*/
  stroke: #d688ff !important;
  stroke-width: 2px !important;
}

.react-flow-dataflows-container .custom-hovered path {
  stroke: #b34be9 !important;
}

nav {
  user-select: none;
}

.ant-select-show-arrow:focus {
  outline: -webkit-focus-ring-color auto 0px !important;
  outline-color: -webkit-focus-ring-color;
  outline-style: auto;
  outline-width: 0px !important;
}

.ant-tooltip .ant-tooltip-inner {
  border: 1px solid #8b8a9b !important;
  background-color: #3f3e5d !important;
  border-radius: 4px !important;
  padding: 8px 10px !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  margin-left: 0 !important; /* block the movement of titles to the right of collapses */
}

/*.react-flow {*/
/*  overflow: auto !important;*/
/*}*/

.react-flow::-webkit-scrollbar {
  height: 4px !important;
}

.react-flow .react-flow__edges {
  z-index: 999 !important;
}
.react-flow-dataflows-container .react-flow .react-flow__edges {
  z-index: 0 !important;
}

.react-flow__attribution {
  display: none !important;
}

.react-flow__node-default,
.react-flow__node-group,
.react-flow__node-input,
.react-flow__node-output {
  background: transparent !important;
  border-radius: 6px !important;
  width: 160px !important;
  height: 74px !important;
  padding: 0 !important;
  border: transparent !important;
}

.react-flow__node-assetNode.selected {
  border: 2px solid #b34be9 !important;
  border-radius: 8px !important;
}

.react-flow__node-default.selected,
.react-flow__node-group.selected,
.react-flow__node-input.selected,
.react-flow__node-output.selected {
  border: 2px solid #b34be9 !important;
  border-radius: 6px !important;
}
.react-flow__pane {
  cursor: grab;
}

.popoverNoPadding .ant-popover-inner-content {
  padding: 0;
}

.fullscreen-image-modal-wrap {
  background-color: black !important;
}

.paginationContainer {
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
}

.connectNode {
  display: block;
  width: 300px;
}

.customHandle {
  width: 100%;
  height: 100%;
  background: blue;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 0;
  transform: none;
  border: none;
  opacity: 0;
  display: none;
}

.ant-tooltip {
  padding-top: 0 !important;
}

.date-time-picker-popup > .ant-picker-panel-container {
  background-color: #1e1f3a !important;
  border: 1px solid #8b8a9b !important;
  border-radius: 4px !important;
}

.ant-picker-time-panel-cell-inner {
  color: white !important;
}

.ant-picker-time-panel-cell-selected div {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner:hover {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.asset-detail-more-drop .ant-tabs-dropdown-menu {
  background: #13122b;
}

.asset-detail-more-drop .asset-tab--selected {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: #fff;
}

.asset-detail-more-drop .asset-tab--selected * {
  margin: 0;
}

.asset-detail-more-drop .asset-tab--selected .number-props--selected {
  display: flex;
  align-items: center;
  font-size: 9px;
  margin-left: 5px;
  color: #090526;
  padding: 4px 12px;
  background: #3f3e5d;
  border-radius: 100px;
  line-height: 12px;
  font-weight: 100;
}

.asset-detail-more-drop .ant-tabs-dropdown-menu-item:hover {
  background: #090526;
}

.feedback-failure-list .ant-select-item-option-content {
  text-transform: lowercase;
  display: block;
}

.feedback-failure-list .ant-select-item-option-content:first-letter {
  text-transform: uppercase;
}

.ant-select-tree-list-scrollbar.ant-select-tree-list-scrollbar-vertical {
  width: 4px !important;
  background: #3f3e5d !important;
  border-radius: 7px !important;
}

.ant-select-tree-list-scrollbar-thumb {
  width: 4px !important;
  background: #949494 !important;
  border-radius: 7px !important;
}
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #b34be9 !important;
  border-right-width: 1px;
  z-index: 1;
}
.scrollbar-thumb {
  width: 4px !important;
  background: #949494 !important;
  border-radius: 7px !important;
}

.feedback-tooltip-container .ant-popover-inner-content {
  padding: 12px !important;
}

.react-datetimerange-picker__inputGroup__amPm option {
  font-size: 14px;
}

.info-tooltips .ant-popover-inner-content {
  padding: 2px 12px;
}

.dash-header-tooltips .ant-popover-inner-content {
  padding: 16px 20px;
}

.tags-wrapper {
  display: flex;
  flex-flow: row wrap;
  gap: 6px;
  max-width: calc(800px - 2px - 40px);
}

.rocognize-dialog .ant-popover-inner-content {
  padding: 0;
}

.rocognize-dialog .ant-tabs-tab {
  padding: 0 0 12px;
}

.rocognize-dialog .ant-tabs-tab:hover {
  color: #b34be9 important;
}
.rocognize-dialog .ant-tabs-nav::before {
  border-bottom: none; /* Elimina la línea inferior por defecto */
}
.ant-tabs-nav::before {
  border-bottom: none; /* Elimina la línea inferior por defecto */
}

.rocognize-dialog .ant-tabs-ink-bar {
  background-color: #b34be9; /* Cambia el color de la línea activa */
}

.rocognize-dialog .ant-tabs-top > .ant-tabs-nav::before {
  border: none;
}

.rocognize-dialog .ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 10px;
}

.layer-type {
  max-height: 32px !important;
}

#pinTooltip {
  height: auto !important;
}

#pinTooltip .ant-popover-inner-content {
  padding: 0 10px;
}
